import { FC, useState, useEffect } from 'react';

import {
  Dialog,
  StyledTrigger,
  BackButton,
  StyledNavigationMenuContentDesktop,
  StyledDialogContentMobile,
  StyledNavigationMenuTriger,
} from '@custom-components/MegaMenu';
import { useRouter } from '@dxp-next';
import { useLinkComponent } from '@link';
import { getIllustration } from '@sitecore/common';
import { Link, MegaMenuV2DivisionRendering, ContentCardRendering } from '@sitecore/types/manual/MegaMenuV2';
import { Box, Grid, Stack, NavLink, Text, Divider, Stretch, PageGrid } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@sparky/icons';

import ContentCard from '../ContentCard/ContentCard';

interface MegaMenuV2DivisionProps {
  fields: MegaMenuV2DivisionRendering;
}

const MegaMenuV2Division: FC<MegaMenuV2DivisionProps> = ({ fields }) => {
  const isDesktop = useMediaQuery('lg');
  return isDesktop ? <Desktop fields={fields} /> : <Mobile fields={fields} />;
};

const Desktop: FC<MegaMenuV2DivisionProps> = ({ fields }) => {
  const { items, link, title } = fields;

  const Link = useLinkComponent();
  const hasFields = fields && items && items.length > 0;
  const hasCard = items?.some(item => item.templateName === 'ContentCard');

  return !hasFields ? (
    <Stretch>
      <Stack alignY="center">
        <Link href={link?.value?.href} linkType={link?.value?.linktype}>
          <NavLink>
            <Text size="BodyL">{title?.value}</Text>
          </NavLink>
        </Link>
      </Stack>
    </Stretch>
  ) : (
    <>
      <StyledNavigationMenuTriger>
        {title?.value}
        <ChevronDownIcon color="iconOnBackgroundVarFive" />
      </StyledNavigationMenuTriger>
      <Stack>
        <StyledNavigationMenuContentDesktop>
          <Box paddingY="16" backgroundColor="backgroundPrimary">
            <PageGrid>
              <PageGrid.Item gridColumn="1/-1">
                <Grid gridTemplateColumns={hasCard || (items?.length && items?.length < 5) ? '2fr 1fr' : 'auto'}>
                  <Stack.Item>
                    <Grid
                      rowGap="10"
                      columnGap="6"
                      gridTemplateColumns={hasCard || (items?.length && items?.length < 5) ? '1fr 1fr' : '1fr 1fr 1fr'}
                      as="ul">
                      {items?.map(item => {
                        const { templateName, fields } = item;

                        if (templateName !== 'TeaserLink') return null;
                        if (!isLink(fields)) return null;

                        const { illustration, link, teaserText } = fields;

                        const Illustration = illustration?.value ? getIllustration(illustration.value) : null;

                        return (
                          <Stack key={item.id} as="li" direction="row" alignY="center" gap="3">
                            {Illustration && <Illustration size="small" color="iconSecondary" />}
                            <Stack>
                              <Link href={link?.value?.href} linkType={link?.value?.linktype} currentMatch="exact">
                                <NavLink>
                                  <Text size="BodyL">{link?.value?.text}</Text>
                                </NavLink>
                              </Link>
                              <Text color="textLowEmphasis" weight="regular" size="BodyS">
                                {teaserText?.value}
                              </Text>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Grid>
                  </Stack.Item>
                  {hasCard ? (
                    <Grid.Item gridColumn="2" gridRow="1/-1">
                      {items?.map(item => {
                        if (item.templateName !== 'ContentCard') return null;
                        if (!isContentCardRendering(item.fields)) return null;

                        return (
                          <ContentCard
                            key={item.id}
                            params={{
                              setElevation: '1',
                            }}
                            componentName="ContentCard"
                            fields={item.fields}
                          />
                        );
                      })}
                    </Grid.Item>
                  ) : null}
                </Grid>
              </PageGrid.Item>
            </PageGrid>
          </Box>
        </StyledNavigationMenuContentDesktop>
      </Stack>
    </>
  );
};

const Mobile: FC<MegaMenuV2DivisionProps> = ({ fields }) => {
  const [isOpen, setOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => setOpen(false);

    router.events.on('beforeHistoryChange', handleRouteChange);
    return () => router.events.off('beforeHistoryChange', handleRouteChange);
  }, [router]);

  const hasFields = fields && fields.items && fields.items.length > 0;
  const Link = useLinkComponent();
  const hasCard = fields?.items?.some(item => item.templateName === 'ContentCard');

  return !hasFields ? (
    <Stack gap="7">
      <Link href={fields.link.value.href} linkType={fields.link.value.linktype}>
        <NavLink>
          <Text size="BodyL">{fields?.title?.value}</Text>
        </NavLink>
      </Link>
      <Divider />
    </Stack>
  ) : (
    <Stack alignX="justify" gap="7" alignY="center">
      <Dialog.Root modal={false} open={isOpen}>
        <StyledTrigger onClick={() => setOpen(prevState => !prevState)}>
          {fields?.title?.value}
          <ChevronRightIcon />
        </StyledTrigger>
        <Divider />
        <StyledDialogContentMobile>
          <Stretch>
            <Box overflow="auto" paddingY="5" paddingX={{ initial: '5', sm: '10' }} backgroundColor="backgroundPrimary">
              <Stack gap="6">
                <BackButton onClick={() => setOpen(prevState => !prevState)}>
                  <ChevronLeftIcon color="currentColor" />
                  {fields?.title?.value}
                </BackButton>
                <Divider />
                <Stack gap="6" direction={{ md: 'row' }}>
                  <Stack.Item grow>
                    <Grid as="ul" rowGap="10" columnGap="6" gridTemplateColumns={{ initial: '1fr', md: '1fr 1fr' }}>
                      {fields?.items?.map(item => {
                        const { templateName, fields } = item;

                        if (templateName !== 'TeaserLink') return null;
                        if (!isLink(fields)) return null;

                        const { illustration, link, teaserText } = fields;

                        const Illustration = illustration?.value ? getIllustration(illustration.value) : null;

                        return (
                          <Grid.Item key={item.id} as="li">
                            <Stack direction="row" alignY="center" gap="3">
                              {Illustration && <Illustration size="small" color="iconSecondary" />}
                              <Stack>
                                <Link href={link?.value?.href} linkType={link?.value?.linktype} currentMatch="exact">
                                  <NavLink>
                                    <Text size="BodyL">{link?.value?.text}</Text>
                                  </NavLink>
                                </Link>
                                <Text color="textLowEmphasis" weight="regular" size="BodyS" aria-hidden>
                                  {teaserText?.value}
                                </Text>
                              </Stack>
                            </Stack>
                          </Grid.Item>
                        );
                      })}
                    </Grid>
                  </Stack.Item>
                  {hasCard && (
                    <Stack.Item grow>
                      {fields?.items?.map(item => {
                        if (item.templateName !== 'ContentCard') return null;
                        if (!isContentCardRendering(item.fields)) return null;

                        return (
                          <ContentCard
                            key={item.id}
                            params={{
                              setElevation: '1',
                            }}
                            componentName="ContentCard"
                            fields={item.fields}
                          />
                        );
                      })}
                    </Stack.Item>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Stretch>
        </StyledDialogContentMobile>
      </Dialog.Root>
    </Stack>
  );
};

function isLink(fields: Link | ContentCardRendering): fields is Link {
  return 'link' in fields && 'illustration' in fields && 'teaserText' in fields;
}

function isContentCardRendering(fields: Link | ContentCardRendering): fields is ContentCardRendering {
  return 'image' in fields && 'title' in fields;
}

export default MegaMenuV2Division;
